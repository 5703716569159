import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseOrganizationContext } from "contexts";
import ProfileHeader from "./ProfileHeader";
import ProfileMain from "./ProfileMain";
import ProfileTabs from "./ProfileTabs";
import Loader from "components/UI/Loader";
import { getAdminOrganization } from "api/services/organization";
import { UseErrorContext } from "contexts";

const Container = styled.div`
  .profile__main {
    margin-top: 8rem;
    margin-left: 5rem;
  }
  .profile__tabs {
    margin-top: 4rem;
  }
`;

const Page = () => {
  const [profileData, setProfileData] = useState(null);
  const { id } = UseOrganizationContext();
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    refreshData();
  }, [id]);

  const refreshData = async () => {
    try{
      if(id){
        let organizationData = await getAdminOrganization(id);
        setProfileData({ ...organizationData, profileSection: organizationData?.profileSections });
      }
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Container className="profile">
      {!profileData ? (
        <Loader center />
      ) : (
        <>
          <div className="profile__header">
            <ProfileHeader {...profileData} _setProfileData={setProfileData} refreshData={refreshData} />
          </div>
          <div className="profile__main">
            <ProfileMain {...profileData} _setProfileData={setProfileData} refreshData={refreshData}/>
          </div>
          <div className="profile__tabs">
            <ProfileTabs profileData={profileData} _setProfileData={setProfileData} id={id} refreshData={refreshData}/>
          </div>
        </>
      )}
    </Container>
  );
};

export default Page;
