import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { editOrganization } from "api/services/organization";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import { AddressForm } from "pages/profile/Components/AddressForm";
import Save from "components/UI/Icons/Save";
import { UseErrorContext } from "contexts";
import TextArea from "components/UI/Forms/TextArea";
import MDEditor from "components/UI/Blocks/MDEditor";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .profile__name {
    width: 30%;
    input {
      width: 100%;
    }
  }
  .profile__description {
    width: 70%;
    margin-left: 10rem;
    textarea {
      width: 100%;
      height: 16rem;
      padding: 2rem;
      border-radius: 0.8rem;
    }
  }

  .contact-details {
    margin-top: 2rem;
  }

  .address__container {
    padding: 0.6rem;
    color: var(--color-light-grey);
    margin-bottom: 1rem;
    & div {
      &:not(:last-of-type) {
        margin-bottom: 0.6rem;
      }
    }
  }

  .save {
    margin-bottom: 1rem;
    position: absolute;
    right: 0;
    &.saved {
      cursor: default;
      svg {
        cursor: default;
        opacity: 0.3;
        path {
          fill: white;
        }
      }
    }
    svg {
      width: 2rem;
      height: auto;
      margin-left: 0.5rem;
    }
  }

  .actions {
    display: flex;
    gap: 2rem;
    position: relative;
    margin-bottom: 3rem;
  }
`;

const ProfileMain = ({ id, name, description, addresses, refreshData }) => {
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [primaryAddress, setPrimaryAddress] = useState(null);
  const [height, setHeight] = useState("16rem");
  const { updateError } = UseErrorContext();
  const { t } = useTranslation();
  useEffect(async () => {
    var edited = descriptionValue != description;
    setIsEdited(edited);
  }, [descriptionValue, description]);

  useEffect(async () => {
    if (addresses.length > 0) {
      let primAddress = addresses.find((a) => a.isPrimary);
      setPrimaryAddress(primAddress ?? addresses[0]);
    } else {
      setPrimaryAddress(null);
    }
  }, [addresses]);

  const saveDescription = async () => {
    if (descriptionValue?.length > 10000) {
      updateError({ message: t("Max. length of text is 10000 characters.") });
      return;
    }
    try {
      if (description != descriptionValue) {
        try {
          setIsSaving(true);
          const resp = await editOrganization(id, { name, description: descriptionValue });
          setIsSaving(false);
          await refreshData();
        } catch (err) {
          updateError(err);
          setIsSaving(false);
        }
      }
    } catch (err) {
      updateError(err);
    }
  };

  const addaddress = () => {
    setShowModal(true);
  };

  const editaddress = () => {
    setShowModalEdit(true);
  };

  return (
    <Container>
      {showModal && (
        <Modal title="Add a new address" hideActions>
          <AddressForm onClose={() => setShowModal(false)} />
        </Modal>
      )}
      {showModalEdit && (
        <Modal title="Edit address" hideActions>
          <AddressForm onClose={() => setShowModalEdit(false)} action={"edit"} initialValue={addresses[0]?.address} />
        </Modal>
      )}
      <div className="content">
        <div className="profile__name">
          <input placeholder="Company name" defaultValue={name} disabled />

          <div className="contact-details">
            {primaryAddress?.address ? (
              <>
                <div className="address__container">
                  <div>{primaryAddress.address.addressLine1}</div>
                  <div>{primaryAddress.address.addressLine2}</div>
                  <div>{primaryAddress.address.addressLine3}</div>
                  <div>{primaryAddress.address.postalCode + " " + primaryAddress.address.city}</div>
                  <div>{primaryAddress.address.countryName}</div>
                </div>
                <div>
                  <Button onClick={editaddress} label={"Edit address"} />
                </div>
              </>
            ) : (
              <Button onClick={addaddress} label={"Add an address"} />
            )}
          </div>
        </div>
        <div className="profile__description">
          <div className="actions">
            {isEdited && (
              <div onClick={() => saveDescription()} className={`icon-button save ${isEdited ? "not-saved" : "saved"}`}>
                <span>{isSaving ? "Saving" : ""}</span>
                <Save />
              </div>
            )}
          </div>
          <MDEditor value={descriptionValue} setFieldValue={(val) => setDescriptionValue(val)} />
        </div>
      </div>
    </Container>
  );
};

export default ProfileMain;
